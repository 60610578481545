import {
	Box,
	Text,
	Button,
	Image,
	Flex,
	Grid,
	GridItem,
	Show,
	Tag,
	Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState, lazy } from "react";
import { MapMarker, EmailDuotone, Pdf, Doc } from "../../img";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	type AppDispatch,
	setPageLoading,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	frappeMethodService,
	BasicDialog,
	getFileType,
	normalDate,
} from "@karkhanaui/react";
import { CustomerCards } from "./CustomerCards";
import { BsCloudArrowUp, BsDownload, BsFileEarmarkCheck } from "react-icons/bs";
const UploadNDA = lazy(async () => await import("./UploadNDA"));
const saveAs = require("file-saver").saveAs;

const CustomerDetails = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const params = useParams();
	const companyId = params.companyId;

	const [customerData, setCustomerData] = useState<any>({});
	const [country, setCountry] = useState<string>("");
	const [customerStatistics, setCustomerStatistics] = useState<any>({
		rfq: 0,
		quote: 0,
		sales_order: 0,
	});
	const [showUploadNDAPopup, setShowUploadNDAPopup] = useState<boolean>(false);
	const [showButtonLoading, setShowButtonLoading] = useState<boolean>(false);
	const [ndaFile, setNdaFile] = useState<any>({});

	const fetchData = async () => {
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		try {
			Promise.all([
				fetch(`/api/resource/Buyer Access/${companyId}`).then((res) =>
					res.json()
				),
				frappeMethodService("supplierv2.api.customer.customer_count", {
					name: companyId,
				}),
			]).then(async (res) => {
				// TODO; currently we have more than 1 primary addresses so removing the primary contacts tag. once the data is cleaned will add it
				//shift the primary address to the top of the list
				// let index = await res.data.company_address.findIndex(
				// 	(item: any) => item?.primary_address
				// );
				// if (index > 0) {
				// 	// Remove the item from its current position
				// 	const [item] = res.data.company_address?.splice(index, 1);
				// 	// Insert the item at the beginning of the array
				// 	res.data.company_address?.unshift(item);
				// }
				let countries = await res[0].data?.company_address?.map(
					(item: any) => item?.country
				);
				let countryCounts = await countries?.reduce((acc: any, item: any) => {
					acc[item] = (acc[item] || 0) + 1;
					return acc;
				}, {});

				if (Object.keys(countryCounts).length > 0) {
					let mostUsedCountry = Object.entries(countryCounts).reduce(
						(highest: any, current: any) => {
							return current[1] > highest[1] ? current : highest;
						}
					)[0];

					setCountry(mostUsedCountry);
				}
				if (res[0]?.data.nda_status === "Pending") {
					setNdaFile({
						filePath: `/api/method/crosslink.nda.get_nda_from_procurement?docname=${res[0]?.data?.nda_doc_name}`,
						fileName: res[0]?.data?.received_nda_file?.split("/")?.pop(),
					});
				} else if (res[0]?.data.nda_status === "Signed") {
					if (res[0]?.data?.signed_nda_file?.length > 0) {
						setNdaFile({
							filePath:
								process.env.REACT_APP_BASE_URL + res[0]?.data?.signed_nda_file,
							fileName: res[0]?.data?.signed_nda_file?.split("/")?.pop(),
						});
					} else {
						setNdaFile({
							filePath: `/api/method/crosslink.nda.get_nda_from_procurement?docname=${res[0]?.data?.nda_doc_name}`,
							fileName: res[0]?.data?.received_nda_file?.split("/")?.pop(),
						});
					}
				}
				setCustomerData(res[0]?.data);
				let _statistics = res[1].data.message.data;
				setCustomerStatistics({
					rfq: _statistics?.rfq,
					quote: _statistics?.quote,
					sales_order: _statistics["sales order"],
				});

				dispatch(
					setPageLoading({
						loading: false,
						loadingText: "",
					})
				);
			});
		} catch (error) {
			dispatch(
				setPageLoading({
					loading: false,
					loadingText: "",
				})
			);
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	useEffect(() => {
		if (companyId != null) {
			fetchData();
		}
	}, [companyId]);

	const keyInGrid = (key: string) => (
		<GridItem>
			<Text color="#3F4254" fontSize="15px" fontWeight={600}>
				{key}
			</Text>
		</GridItem>
	);

	const valueInGrid = (values: any) => (
		<GridItem>
			<Flex alignItems="center" justifyContent="space-between" gap="1.25rem">
				{values?.map((value: string, index: number) => (
					<Text
						key={index}
						p=".75rem 1rem"
						bg="#F5F8FA"
						color={value ? "#3F4254" : "rgba(0,0,0,0.5)"}
						borderRadius=".375rem"
						flex={1}
						fontWeight={600}
					>
						{value || "NA"}
					</Text>
				))}
			</Flex>
		</GridItem>
	);

	const downloadFile = async (url: string, fileName: string) => {
		setShowButtonLoading(true);
		const response: any = await fetch(url);
		const blob = await response.blob();
		saveAs(blob, fileName);
		setShowButtonLoading(false);
	};

	const getFileIcon = (fileName: string) => {
		switch (getFileType(fileName)) {
			case "pdf":
				return Pdf;
			case "word":
				return Doc;
			default:
				return Pdf;
		}
	};

	return (
		<Box m="1rem">
			<Show above="md">
				<Flex
					width="100%"
					alignItems="center"
					justifyContent="space-between"
					padding="2rem 2.75rem 2rem 3.375rem"
					gap="3em"
					borderRadius="lg"
					bg="#fff"
					flexWrap="wrap"
				>
					<Box display="flex" alignItems="center" gap="4.1875rem">
						<Flex
							width="6.25rem"
							height="6.25rem"
							objectFit="contain"
							borderRadius="50%"
							bg="#E2F1EE"
							alignItems="center"
							justifyContent="center"
						>
							<Text fontSize="50px" fontWeight="700" color="#4BA18A">
								{customerData.company_name?.charAt(0)?.toUpperCase()}
							</Text>
						</Flex>
						<Box>
							<Text
								fontSize="1.5rem"
								fontWeight="600"
								color="#3F4254"
								textShadow="2px 2px rgba(0,0,0,0.1)"
							>
								{customerData?.company_name}
							</Text>
							{country && (
								<Flex alignItems="center" gap=".375rem" mt=".875rem">
									<Image src={MapMarker} />
									<Text color="rgba(0, 0, 0, 0.60)" fontSize=".875rem">
										{country}
									</Text>
								</Flex>
							)}
							{customerData?.company_email && (
								<Flex alignItems="center" gap=".375rem" mt=".375rem">
									<Image src={EmailDuotone} />
									<Text color="rgba(0, 0, 0, 0.60)" fontSize=".875rem">
										{customerData.company_email}
									</Text>
								</Flex>
							)}
						</Box>
					</Box>
					<Box>
						<Flex alignItems="center" gap="1rem" flexWrap="wrap">
							<Button
								variant="outline"
								color="#4BA18A"
								border="1px solid #4BA18A"
								_hover={{ bg: "#E2F1EE" }}
								onClick={() =>
									navigate(
										`/rfqmain/filter?company_name=${customerData?.company_hex}`
									)
								}
							>
								View RFQs&nbsp;({customerStatistics?.rfq})
							</Button>
							<Button
								variant="outline"
								color="#4BA18A"
								border="1px solid #4BA18A"
								_hover={{ bg: "#E2F1EE" }}
								onClick={() =>
									navigate(
										`/quotes/filter?company_name=${customerData?.company_hex}`
									)
								}
							>
								View Quotes&nbsp;({customerStatistics?.quote})
							</Button>
							<Button
								variant="outline"
								color="#4BA18A"
								border="1px solid #4BA18A"
								_hover={{ bg: "#E2F1EE" }}
								onClick={() =>
									navigate(
										`/orders/filter?company_name=${customerData?.company_hex}`
									)
								}
							>
								View Orders&nbsp;({customerStatistics?.sales_order})
							</Button>
						</Flex>
					</Box>
				</Flex>
			</Show>
			<Show below="md">
				<CustomerCards
					buyerName={customerData?.company_name}
					buyerString={customerData?.company_name}
					rfq={customerStatistics?.rfq}
					quote={customerStatistics?.quote}
					sales_order={customerStatistics?.sales_order}
					disableCustomerNavigation={true}
					company_hex={customerData?.company_hex}
				/>
			</Show>
			{["Pending", "Signed"].includes(customerData?.nda_status) && (
				<Flex
					bg="#fff"
					p="1.5rem"
					mt="1rem"
					borderRadius=".75rem"
					alignItems="center"
					justifyContent="space-between"
					gap="1rem"
					flexWrap="wrap"
				>
					<Box>
						<Flex alignItems="center" gap="1rem" mb=".5rem">
							<Text fontSize="1.125rem" fontWeight="600">
								Non Disclosure Agreement
							</Text>
							<Tag
								fontSize=".75rem"
								fontWeight="600"
								bg={
									customerData?.nda_status == "Pending"
										? "rgba(253, 185, 19, 0.2)"
										: "#E8FFF3"
								}
								color={
									customerData?.nda_status == "Pending" ? "#EBA700" : "#50B848"
								}
							>
								{customerData?.nda_status}
							</Tag>
						</Flex>
						{customerData?.nda_status == "Pending" ? (
							<Text color="#21212180" fontSize="1rem" fontWeight={400}>
								You have received an NDA from the customer. Download the NDA and
								upload the signed copy.
							</Text>
						) : (
							<Text color="#21212180" fontSize="1rem" fontWeight={400}>
								You have signed the NDA with this customer.
								<br /> Note: If you want to delete the NDA signed by you, kindly
								contact <br /> the Kkonnect support team.
							</Text>
						)}
						{customerData?.nda_status == "Pending" && (
							<Flex alignItems="center" gap="1.5rem" mt="1rem">
								{ndaFile?.fileName && (
									<Button
										children="Download NDA"
										variant="outline"
										color="#4BA18A"
										borderColor="#4BA18A"
										leftIcon={<BsDownload size="1.25rem" />}
										_hover={{ bg: "#E2F1EE" }}
										onClick={() =>
											downloadFile(ndaFile?.filePath, ndaFile?.fileName)
										}
										isLoading={showButtonLoading}
									/>
								)}
								<Button
									children="Upload Signed NDA"
									leftIcon={<BsCloudArrowUp size="1.25rem" />}
									color="#fff"
									bg="#4BA18A"
									_hover={{ bg: "#38846D" }}
									onClick={() => setShowUploadNDAPopup(true)}
								/>
							</Flex>
						)}
					</Box>
					{ndaFile?.fileName && (
						<Flex
							alignItems="flex-start"
							justifyContent="space-between"
							gap="4.1875rem"
							border="1px dashed #4BA18A"
							p="1.5rem"
							borderRadius=".25rem"
							bg={
								customerData?.nda_status == "Pending"
									? ""
									: "rgba(226, 241, 238, 0.20)"
							}
						>
							<Flex alignItems="flex-start" gap=".5rem">
								<Image src={getFileIcon(ndaFile?.fileName)} alt="fileIcon" />
								<Box>
									<Tooltip label={ndaFile?.fileName}>
										<Text fontWeight={600}>
											{ndaFile?.fileName?.substring(0, 25)}
										</Text>
									</Tooltip>
									{customerData?.nda_status === "Pending" &&
										customerData?.received_on != null && (
											<Text
												fontSize="13px"
												fontWeight="semibold"
												color="#B5B5C3"
											>{`Received on ${normalDate(
												customerData?.received_on
											)}`}</Text>
										)}
									{customerData?.nda_status === "Signed" &&
										customerData?.signed_on != null && (
											<Text
												fontSize="13px"
												fontWeight="semibold"
												color="#B5B5C3"
											>{`Signed on ${normalDate(
												customerData?.signed_on
											)}`}</Text>
										)}
								</Box>
							</Flex>
							{customerData?.nda_status == "Pending" ? (
								<Text
									color="#4BA18A"
									fontWeight="semibold"
									cursor="pointer"
									onClick={() => {
										window.open(ndaFile?.filePath, "_blank");
									}}
								>
									View
								</Text>
							) : (
								<Button
									children="View Signed NDA"
									leftIcon={<BsFileEarmarkCheck />}
									color="#fff"
									bg="#4BA18A"
									_hover={{ bg: "#38846D" }}
									onClick={() => {
										window.open(ndaFile?.filePath, "_blank");
									}}
								/>
							)}
						</Flex>
					)}
				</Flex>
			)}
			<Box
				bg="#fff"
				p="1.375rem 1.875rem 1.125rem 1.875rem"
				mt="1rem"
				borderRadius=".75rem .75rem 0 0"
				borderBottom="1px solid #BABCBE"
			>
				<Text fontSize="20px" fontWeight="700">
					Customer Details
				</Text>
			</Box>
			<Box
				bg="#fff"
				p="1.875rem 1.875rem 2.5rem 1.875rem"
				borderRadius="0 0 .75rem .75rem"
			>
				<Grid
					templateColumns="repeat(2,1fr)"
					alignItems="center"
					rowGap="1.25rem"
				>
					{keyInGrid("Full Name")}
					{valueInGrid([customerData?.customer_name])}
					{keyInGrid("Company Name")}
					{valueInGrid([customerData?.company_name])}
					{keyInGrid("Contact Phone")}
					{valueInGrid([customerData?.customer_mobile_no])}
					{keyInGrid("GST Number")}
					{valueInGrid([customerData?.company_gst])}
					{keyInGrid("Company Site")}
					{valueInGrid([customerData?.company_website])}
					{keyInGrid("Country")}
					{valueInGrid([country])}
				</Grid>
			</Box>

			<Box
				bg="#fff"
				p="1.375rem 1.875rem 1.125rem 1.875rem"
				mt="1rem"
				borderRadius=".75rem .75rem 0 0"
				borderBottom="1px solid #BABCBE"
			>
				<Text fontSize="20px" fontWeight="700">
					Address & Contact Details
				</Text>
			</Box>
			<Box bg="#fff" p="1.875rem" borderRadius="0 0 .75rem .75rem">
				<Grid
					templateColumns={{
						sm: "repeat(1,1fr)",
						md: "repeat(2,1fr)",
					}}
					columnGap="2.25rem"
				>
					<GridItem>
						{customerData.company_address?.map((item: any, index: number) => (
							<Box
								key={index}
								padding="1.6875rem 1.9375rem"
								border="1px dashed #BABCBE"
								borderRadius=".375rem"
								mb="1rem"
							>
								<Flex alignItems="center" gap=".25rem" mb=".75rem">
									<Text fontSize="1.125rem" fontWeight={800} color="#3F4254">
										Address&nbsp;{index + 1} - {item?.address_title}
									</Text>
									{/* TODO; currently we have more than 1 primary addresses so
									removing the primary contacts tag. once the data is cleaned
									will add it */}
									{/* {item?.primary_address == 1 && (
										<Text
											padding=" 0.375rem 0.75em"
											color="#50CD89"
											fontSize="10px"
											fontWeight={600}
											backgroundColor="#E8FFF3"
											borderRadius=".375rem"
										>
											Primary
										</Text>
									)} */}
								</Flex>
								<Text fontWeight={600} color="#3F4254" opacity=".8">
									{item?.address_line_1}
								</Text>
								<Text fontWeight={600} color="#3F4254" opacity=".8">
									{item?.city ? `${item?.city}, ` : ""}
									{item?.state ? `${item?.state}, ` : ""}
									{item?.country ? `${item?.country},` : ""}
								</Text>
								<Text fontWeight={600} color="#3F4254" opacity=".8">
									{item?.pincode}
								</Text>
							</Box>
						))}
					</GridItem>

					<GridItem>
						{customerData.customer_contacts?.length > 0 && (
							<Flex
								flexDirection="column"
								gap="2rem"
								padding="1.6875rem 1.9375rem"
								border="1px dashed #BABCBE"
								borderRadius=".375rem"
							>
								{customerData.customer_contacts?.map(
									(item: any, index: number) => (
										<Box key={index}>
											<Text
												fontSize="1.125rem"
												fontWeight={800}
												color="#3F4254"
												mb=".75rem"
											>
												Contact&nbsp;{index + 1}
											</Text>
											<Text fontWeight={600} color="#3F4254" opacity=".8">
												Phone:&nbsp;
												<span
													style={{
														color: item?.customer_mobile
															? "#3F4254"
															: "rgba(0,0,0,0.5)",
													}}
												>
													{item?.customer_mobile || "NA"}
												</span>
											</Text>
											<Text fontWeight={600} color="#3F4254" opacity=".8">
												Email:&nbsp;
												<span
													color={item?.email ? "#3F4254" : "rgba(0,0,0,0.5)"}
												>
													{item?.email || "NA"}
												</span>
											</Text>
										</Box>
									)
								)}
							</Flex>
						)}
					</GridItem>
				</Grid>
			</Box>
			<BasicDialog
				isOpen={showUploadNDAPopup}
				onClose={() => {
					setShowUploadNDAPopup(false);
				}}
				showCloseButton={true}
				header="Upload NDA"
				size="2xl"
				content={
					<Box p="1rem">
						<UploadNDA
							customerName={customerData?.company_name}
							RefetchData={() => {
								setShowUploadNDAPopup(false);
								fetchData();
							}}
							customerDocName={companyId}
						/>
					</Box>
				}
			/>
		</Box>
	);
};

export default CustomerDetails;
