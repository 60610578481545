import React, { useState, useEffect } from "react";
import {
	Button,
	Table,
	Tbody,
	Tr,
	Box,
	Divider,
	Td,
	Input,
	Text,
	Flex,
	Show,
} from "@chakra-ui/react";

const AddRateRightSide: React.FC<any> = (props: any) => {
	const [rate, setRate] = useState<any>(props.rate);
	const [costBreakUp, setCostBreakUp] = useState<any>(props.costBreakUp);

	useEffect(() => {
		if (props) {
			setRate(props.rate);
			setCostBreakUp(props.costBreakUp);
		}
	}, [props]);

	const getCostName = (keyName: string) => {
		if (keyName === "post_process") {
			return "Post Process Cost";
		} else if (keyName.includes("_")) {
			return keyName
				.split("_")
				.map((word) => {
					return word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(" ");
		} else if (keyName.includes(" ")) {
			return keyName
				.split(" ")
				.map((word) => {
					return word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(" ");
		} else {
			return keyName;
		}
	};

	const handleInputChange = (key: any, value: any) => {
		setCostBreakUp((prevData: any) => ({
			...prevData,
			[key]: value,
		}));
	};

	return (
		<Box>
			<Show above="md">
				<Text textStyle="primary.bold" m="1rem 1.5rem">
					Add Rate
				</Text>
				<Divider />
			</Show>
			<Box margin="1.5rem">
				<Flex>
					<Flex
						width="40%"
						border="1px solid #4BA18A"
						alignItems="center"
						justifyContent="center"
						borderRadius="8px 0px 0px 8px"
					>
						<Text color="#4BA18A" marginY="1rem" textStyle="primary.bold">
							Rate*
						</Text>
					</Flex>
					<Flex
						width="60%"
						alignItems="center"
						justifyContent="center"
						border="1px solid #4BA18A"
						borderRadius="0px 8px 8px 0px"
					>
						<Input
							placeholder="Add Rate"
							variant="Unstyled"
							value={rate}
							type="number"
							onChange={(e) => {
								setRate(e.target.value);
							}}
						></Input>
					</Flex>
				</Flex>
				{Object.keys(costBreakUp)?.length > 0 && (
					<Box mt="1.12rem">
						<Divider style={{ height: "2px" }} />
						<Text fontWeight="bold" mt="1.125rem">
							Breakup
						</Text>
						<Text color="rgba(0, 0, 0, 0.60)" fontSize=".875rem">
							Customer has asked for the following breakup cost. Share the
							breakup cost to increase the chances of getting order and a quick
							response from the customer
						</Text>
						<Table border="1px solid #4BA18A" mt="1.375rem">
							<Tbody>
								{Object.keys(costBreakUp)?.map((key: any) => (
									<Tr key={key}>
										<Td border="1px solid #4BA18A">
											<Text color="#4BA18A" textStyle="primary.bold">
												{getCostName(key)}
											</Text>
										</Td>
										<Td border="1px solid #4BA18A" p={0} m={0}>
											<Input
												placeholder="Add Rate"
												type="number"
												variant="Unstyled"
												value={costBreakUp[key]}
												onChange={(e) => {
													handleInputChange(key, e.target.value);
												}}
											/>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				)}
				<Flex
					mt="2.8rem"
					alignItems="center"
					justifyContent={{ base: "center", md: "flex-end" }}
					gap="1.25rem"
					w={{ base: "100%", md: "auto" }}
				>
					<Button
						bg="#fff"
						border="1px solid #000"
						onClick={() => props.closeAddRateModal()}
						w={{ base: "50%", md: "auto" }}
					>
						Cancel
					</Button>
					<Button
						bg="#4BA18A"
						_hover={{ bg: "#38846D" }}
						color="#fff"
						onClick={() => props.updateRateAndCloseModal(rate, costBreakUp)}
						w={{ base: "50%", md: "auto" }}
					>
						Save
					</Button>
				</Flex>
			</Box>
		</Box>
	);
};

export default AddRateRightSide;
