import React, { type FC, useState, useEffect } from "react";
import { Grid, GridItem, Box, Show } from "@chakra-ui/react";
import PartConfigureLeftSide from "./PartConfigureLeftSide";
import ItemDataDisplay from "./ItemDataDisplay";
import {
	getItemAttachedFilesService,
	type AppDispatch,
	formatErrorMessage,
	SET_MESSAGE,
	ERROR_MSG,
	setPageLoading,
	frappeMethodService,
} from "@karkhanaui/react";
import { useDispatch } from "react-redux";
import AddRateRightSide from "./AddRateRightSide";
interface RateProps {
	onSave: () => void;
	itemData: any;
	selectedItemForRate: any;
	closeAddRateModal: Function;
	updateRateAndCloseModal: Function;
	dataOriginatedFrom?: string;
}

const AddRateModal: FC<RateProps> = ({
	onSave,
	itemData,
	selectedItemForRate,
	closeAddRateModal,
	updateRateAndCloseModal,
	dataOriginatedFrom,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [attachedFiles, setAttachedFiles] = useState<any>([]);
	const [costBreakUp, setCostBreakUp] = useState<any>([]);
	const [rate, setRate] = useState<any>();

	const getAttachedFiles = async () => {
		const params = {
			filters: JSON.stringify([
				["attached_to_doctype", "=", "Item"],
				["attached_to_name", "in", itemData?.item_code],
			]),
			fields: JSON.stringify(["*"]),
			limit: 0,
		};
		try {
			let res: any;
			if (dataOriginatedFrom === "procurement") {
				res = await frappeMethodService(
					"crosslink.api.get_attachments_from_procurement",
					{
						doctype: "Item",
						uuid: itemData?.item_uuid,
					}
				);
			} else {
				res = await getItemAttachedFilesService(params);
			}
			if (dataOriginatedFrom === "procurement") {
				if (res.status === 200) {
					setAttachedFiles(res.data.message.data);
				}
			} else {
				if (res.status === 200) {
					setAttachedFiles(res.data.data);
				}
			}
		} catch (error) {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: formatErrorMessage(error),
				},
			});
		}
	};

	const fetchdata = async () => {
		let costBreakupJSON = {
			material_cost: 0,
			process_cost: 0,
			post_process: 0,
			overhead_cost: 0,
		};
		dispatch(
			setPageLoading({
				loading: true,
				loadingText: "Loading...",
			})
		);
		await getAttachedFiles();
		setRate(selectedItemForRate?.rate);

		if (selectedItemForRate?.cost_breakup) {
			const _temp: any = {
				...JSON.parse(selectedItemForRate?.cost_breakup),
			};

			const _oppoCostBreakup: any = itemData?.cost_breakup
				? {
						...JSON.parse(itemData?.cost_breakup),
				  }
				: costBreakupJSON; //erp quotations dont have cost breakup. in that case use the deafult breakup with all the cost requirement as false

			const newCosts: any = {};
			await Object.keys(_temp)?.map((key: any) => {
				if (key === "post_process_cost") {
					return;
				} else if (_oppoCostBreakup[key] > 0) {
					newCosts[key] = _temp[key] == 1 ? 0 : _temp[key]; //default value as 0
				}
			});
			setCostBreakUp(newCosts);
		}
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
	};

	useEffect(() => {
		if (selectedItemForRate && itemData) {
			fetchdata();
		}
	}, [selectedItemForRate, itemData]);

	return (
		<Grid templateColumns="repeat(2, 1fr)" gap="1rem">
			<Show above="md">
				<GridItem>
					<Box
						bg="#fff"
						borderRadius="12px"
						maxHeight="86vh"
						overflowY="scroll"
						p=".5rem"
					>
						<PartConfigureLeftSide
							attachedFiles={attachedFiles}
							dataOriginatedFrom={dataOriginatedFrom}
						/>
						<ItemDataDisplay
							itemData={itemData}
							dataOriginatedFrom={dataOriginatedFrom}
						/>
					</Box>
				</GridItem>
			</Show>
			<GridItem bg="#fff" borderRadius="12px" colSpan={{ base: 2, md: 1 }}>
				<AddRateRightSide
					rate={rate}
					costBreakUp={costBreakUp}
					closeAddRateModal={closeAddRateModal}
					updateRateAndCloseModal={updateRateAndCloseModal}
				/>
			</GridItem>
		</Grid>
	);
};

export default AddRateModal;
